import React, { useEffect } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { paths } from 'routing';
import { useInView } from 'react-intersection-observer';
import { useAppDispatch } from 'state/hooks';
import { track } from 'state/app';
import Link from 'next/link';

interface IFooter {
    className?: string;
    onVisibilityChange?: (visible: boolean) => void;
}

const Footer: React.FunctionComponent<IFooter> = (props) => {
    const { className, onVisibilityChange } = props;
    const [ref, inView] = useInView({
        rootMargin: '100px',
    });

    const dispatch = useAppDispatch();

    const handleLinkClick = (link: string) =>
        dispatch(
            track({
                event: 'Click Footer Link',
                link,
            })
        );

    useEffect(() => {
        if (onVisibilityChange) {
            onVisibilityChange(inView);
        }
    }, [inView, onVisibilityChange]);

    return (
        <FooterStyled className={className} ref={ref}>
            <SectionColumnStyled>
                <LinkColumnStyled>
                    <HeadingStyled>Legal</HeadingStyled>
                    <ul>
                        <li>
                            <LinkStyled
                                href={paths.terms()}
                                title="Terms"
                                target="_blank"
                                rel="noopener"
                                onClick={() => handleLinkClick('terms')}
                            >
                                Terms
                            </LinkStyled>
                        </li>
                        <li>
                            <LinkStyled
                                href={paths.copyright()}
                                title="Copyright"
                                target="_blank"
                                rel="noopener"
                                onClick={() => handleLinkClick('copyright')}
                            >
                                Copyright
                            </LinkStyled>
                        </li>
                        <li>
                            <LinkStyled
                                href={paths.privacy()}
                                title="Privacy"
                                target="_blank"
                                rel="noopener"
                                onClick={() => handleLinkClick('privacy')}
                            >
                                Privacy
                            </LinkStyled>
                        </li>
                    </ul>
                </LinkColumnStyled>
                <LinkColumnStyled>
                    <HeadingStyled>Help</HeadingStyled>
                    <ul>
                        <li>
                            <LinkStyled
                                href="mailto:contact@getplayback.com?subject=Playback Beta"
                                title="Contact"
                                target="_blank"
                                onClick={() => handleLinkClick('contact')}
                            >
                                Contact
                            </LinkStyled>
                        </li>
                        <li>
                            <LinkStyled
                                href={paths.faq()}
                                title="FAQ"
                                target="_blank"
                                rel="noopener"
                                onClick={() => handleLinkClick('faq')}
                            >
                                FAQ
                            </LinkStyled>
                        </li>
                    </ul>
                </LinkColumnStyled>
                <LinkColumnStyled>
                    <HeadingStyled>Company</HeadingStyled>
                    <ul>
                        <li>
                            <LinkStyled
                                href={paths.about()}
                                title="About"
                                target="_blank"
                                rel="noopener"
                                onClick={() => handleLinkClick('about')}
                            >
                                About
                            </LinkStyled>
                        </li>
                        <li>
                            <Link href="/jobs">
                                <LinkStyled
                                    href="/jobs"
                                    title="Careers"
                                    onClick={() => handleLinkClick('careers')}
                                >
                                    Careers
                                </LinkStyled>
                            </Link>
                        </li>
                        <li>
                            <LinkStyled
                                rel="noopener"
                                href="https://www.twitter.com/watchplayback"
                                target="_blank"
                                onClick={() => handleLinkClick('twitter')}
                            >
                                Twitter <img src="/img/twitter.png" alt="Twitter" />
                            </LinkStyled>
                        </li>
                        <li>
                            <LinkStyled
                                rel="noopener"
                                href="https://discord.gg/vBkzkH2ptQ"
                                target="_blank"
                                onClick={() => handleLinkClick('discord')}
                            >
                                Discord <img src="/img/discord.png" alt="Discord" />
                            </LinkStyled>
                        </li>
                    </ul>
                </LinkColumnStyled>
            </SectionColumnStyled>
            <SectionColumnStyled>
                <CopyrightStyled>{`© ${new Date().getFullYear()} Playback Inc.`}</CopyrightStyled>
            </SectionColumnStyled>
        </FooterStyled>
    );
};

const LinkStyled = styled.a`
    color: ${({ theme }) => theme.palette.LightGrey1};
    font-size: ${rem(16)};
    transition: color 100ms ease;
    padding: ${rem(8)} 0;
    display: flex;
    align-items: center;

    img {
        height: ${rem(16)};
        width: ${rem(16)};
        margin-left: ${rem(8)};
    }

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.White};
            text-decoration: underline;
            text-underline-offset: ${rem(4)};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(13)};
        padding: ${rem(5)} 0;
    }
`;

const CopyrightStyled = styled.p`
    color: ${({ theme }) => theme.palette.LightGrey1};
    font-size: ${rem(14)};
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin: 0;
    }
`;

const HeadingStyled = styled.div`
    ${({ theme }) => theme.typography.FontMedium};
    color: ${({ theme }) => theme.palette.White};
    font-size: ${rem(16)};
    margin-bottom: ${rem(8)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(13)};
        margin-bottom: ${rem(5)};
    }
`;

const LinkColumnStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & + & {
        margin-left: ${rem(20)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        & + & {
            margin-left: ${rem(80)};
        }
    }
`;

const SectionColumnStyled = styled.div`
    margin: ${rem(12)};
    display: flex;
    justify-content: space-between;
    max-width: ${rem(280)};
    width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin: ${rem(40)};
        justify-content: flex-start;
        max-width: none;
        width: auto;
    }
`;

const FooterStyled = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    flex-shrink: 0;
    padding: ${rem(24)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
    }
`;

export default Footer;
